footer {
    position: relative;
    z-index: 2;
    background: $violet;
    margin-top: 10px;

    &::after {
        content: '';
        position: absolute;
        top: -65px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $violet;
        transform: skewY(-5deg);
        z-index: -1;
    }
}

.footer-top {
    padding: 20px 0 40px 0;

    @include media ("<tablet") {
        padding: 0 0 40px 0;
    }

    .content {
        display: flex;
        justify-content: space-between;

        @include media ("<tablet") {
            flex-direction: column;
            align-items: center;
        }
    }

    .text {
        margin-right: 50px;

        @include media ("<tablet") {
            margin-right: 0;
        }

        p {
            line-height: 25 / 22 * 100%;
            font-size: 22px;
            max-width: 665px;
            text-align: start;

            @include media ("<widescreen") {
                font-size: 16px;
                margin-bottom: 35px;
            }

            @include media ("<tablet") {
                text-align: center;
            }
        }

    }

    .contacts {
        margin-left: 20px;

        @include media ("<tablet") {
            margin: 0;
            text-align: center;
        }

        a {
            color: $gray;

            &:hover {
                color: $white;
                transition: all 0.4s ease;

                svg {
                    fill: $white;
                }
            }
        }

        svg {
            margin-right: 15px;
            fill: $gray;
            transition: all 0.4s ease;
        }

        p {
            font-size: 14px;
            color: $gray;
            line-height: 14 / 16 * 100%;
            margin: 10px 0 20px 0;

            @include media ("<tablet") {
                margin: 15px 0 25px 0;
            }
        }
    }

    .tel {
        font-size: 35px;
        line-height: 30 / 34 * 100%;
        color: $gray;
        white-space: nowrap;

        @include media ("<widescreen") {
            font-size: 30px;
        }
    }

    .mail {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 20px;
        line-height: 17 / 20 * 100%;
        color: $gray;

        @include media ("<tablet") {
            font-size: 15px;
            justify-content: center;
        }

        img {
            margin-right: 15px;
        }
    }
}



.footer-bot {
    background: #2D1596;
}

.terms {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media ("<tablet") {
        flex-direction: column;
        text-align: center;
    }

    @include media ("<phone") {
        padding: 25px 0 30px 0;
    }

    p {
        font-size: 12px;

        @include media ("<widescreen") {
            font-size: 10px;
        }
    }

    span {

        a {
            font-size: 12px;

            &:hover {
                color: $white;
            }

            @include media ("<widescreen") {
                font-size: 10px;
            }

            @include media ("<tablet") {
                font-size: 12px;
            }
        }

        @include media ("<tablet") {
            margin-bottom: 30px;
        }
    }

    span,
    a,
    p {
        color: $gray;

        @include media (">1750px") {
            font-size: 16px;
        }
    }
}