// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$black: #000;
$white: #FFFFFF;
$gray: #B3B3B3;
$violet: #401FCF;
$orange: #ED863C;


$base-font-sans-serif: 'Ubuntu',
    sans-serif;
$base-font-serif: 'Times New Roman',
    'Times',
    'Baskerville',
    'Georgia',
    serif;
$roboto: 'Roboto',
    $base-font-sans-serif;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $black  !default;
$base-background-color: $white  !default;
$font-size-base: 16px !default;
$line-height-base: 1.2 !default;
$base-font-family: $base-font-sans-serif  !default;

$vertical-rhythm: (
    $line-height-base * 1em
);

$base-min-width: 320px;

// Liks
$base-link-color: $black;

// Buttons 
$btn-color: $white  !default;
$btn-background: $black  !default;
$btn-padding: 0.4em 0.7em !default;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: $btn-padding  !default;
$form-element-border-color: $gray  !default;
$form-element-focus-border-color: $black  !default;
$placeholder-color: $gray  !default;

// Headers
$h1-font-size: 32px;
$h2-font-size: 28px;
$h3-font-size: 24px;
$h4-font-size: 22px;
$h5-font-size: 17px;
$h6-font-size: 15px;

$headings-font-family: $base-font-sans-serif;
$headings-color: inherit !default;


$animation-speed: 0.3s;