// Typography

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
	font-family: $headings-font-family;
	color: $headings-color;
	margin: 0;
	font-weight: 700;
}

h1,
.h1 {
	font-size: 64px;

	@include media ("<widescreen") {
		font-size: 48px;
	}

	@include media ("<tablet") {
		font-size: 36px;
	}

	line-height: 74 / 64 * 100%;
	color: $white;
}

h2,
.h2 {
	font-size: 48px;

	@include media ("<widescreen") {
		font-size: 36px;
	}

	line-height: 55 / 48 * 100%;
	color: $white;
}

h3,
.h3 {
	line-height: 1.1;
	color: $white;
	font-size: 25px;
}

h4,
.h4 {
	font-weight: 700;
	line-height: 1.1;
	color: $white;
	font-size: 25px;
}

h5,
.h5 {
	font-size: $h5-font-size;
}

h6,
.h6 {
	font-size: $h6-font-size;
}

p {
	color: $gray;
	margin: 0;
	line-height: 21 / 18 * 100%;
}

a {
	color: $base-link-color;
	text-decoration: none;
	transition: color .3s ease;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

ul {
	margin: 0;
	padding: 0;
}

li {
	list-style: none;
}