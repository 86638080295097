body {
    font-family: 'Ubuntu', sans-serif;
    height: 100%;
    z-index: 1;
}

html {
    /* scroll-behavior: smooth; */
    height: 100%;
}

#wrapper {
    min-height: 100%;
    background: $black;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1 1 auto;
}

.body_lock {
    overflow: hidden;
}

.bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    z-index: 0;

    transform: translate(-50%);
    background: rgb(61, 61, 61);
    background: radial-gradient(circle, rgba(61, 61, 61, 0.7539390756302521) 15%, rgba(0, 0, 0, 1) 100%);

    @include media ("<desktop") {
        background: radial-gradient(ellipse, rgba(61, 61, 61, 0.7539390756302521) -10%, rgba(0, 0, 0, 1) 100%);
    }
}

.btn {
    position: relative;
    padding: 18px 55px 18px 25px;
    background: $violet;
    color: $white;
    background-image: linear-gradient(#ED863C, #ED863C);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: all 0.5s ease;
    min-width: 270px;
    white-space: nowrap;
    display: inline-block;
    font-size: 18px;
    font-weight: 700;

    @include media ("<phone") {
        text-align: start;
    }


    &:hover {
        background-size: 100% 100%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 3px;
        height: 100%;
        background: $orange;
    }

    a {
        font-size: 18px;
        font-weight: 700;
        color: $white;
    }

    img {
        margin-left: 50px;
        height: 15px;
        width: 7px;
        position: absolute;
        top: 20px;
        right: 30px;
    }
}

.cont {
    max-width: 1350px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    @include media ("<phone") {
        padding: 0 10px;
    }
}

.first {
    padding: 75px 0 40px 0;
    position: relative;

    @include media ("<desktop") {
        padding: 130px 0 30px 0;
    }

    @include media ("<tablet") {
        padding: 60px 0 35px 0;
    }

    .content {
        display: flex;
        justify-content: space-between;


        @include media ("<tablet") {
            flex-direction: column-reverse;
            align-items: center;
        }
    }

    .text {
        width: 49%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @include media ("<tablet") {
            align-items: center;
            width: 100%;
            padding-top: 0;
        }

    }

    .title {
        margin-bottom: 15px;
        font-size: 22px;

        @include media ("<desktop") {
            font-size: 18px;
        }

        @include media ("<phone") {
            font-size: 15px;
        }

        line-height: 25 / 22 * 100%;
        font-weight: 500;
        color: $gray;
    }

    .headline {
        margin-bottom: 25px;

        @include media ("<desktop") {
            margin-bottom: 40px;
        }

        @include media ("<tablet") {
            margin-bottom: 25px;
        }
    }

    .first-description {
        margin-bottom: 55px;
        max-width: 455px;
        font-size: 18px;
        text-align: start;

        @include media ("<desktop") {
            font-size: 15px;
            margin-bottom: 95px;
        }

        @include media ("<tablet") {
            margin-bottom: 40px;
            max-width: 100%;
            text-align: center;
        }

        @include media ("<phone") {
            margin-bottom: 50px;
            font-size: 14px;
        }
    }

    .btn {
        margin-bottom: 100px;

        @include media ("<tablet") {
            margin-bottom: 30px;
        }
    }

    .links-app {
        display: flex;

        a:first-child {
            margin-right: 30px;
        }

        a {
            transition: 0.5s;

            &:hover {
                transform: scale(1.1);
                transition: 0.5s;
            }
        }
    }

    .img-wrap {
        width: 49%;

        @include media ("<desktop") {
            text-align: center;
            min-height: 350px;
            margin-left: 20px
        }

        @include media ("<tablet") {
            margin: 0 0 10px 0;
            width: 100%;
        }
    }
}

.guarantee {
    @include adaptiv-vaule("padding-top", 50, 1, 1);
    padding-bottom: 105px;
    @include adaptiv-vaule("margin-top", 200, 50, 1);
    @include adaptiv-vaule("margin-bottom", 150, 50, 1);
    background: #1D1D1D;
    transform: skewY(-5deg);
    position: relative;

    @include media ("<desktop") {
        padding-bottom: 50px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 10px solid transparent;
        border-left: 100vw solid $orange;
        border-top: 0px solid $orange;
    }

    .content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-height: 500px;
        transform: skewY(5deg);

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .text {
        @include adaptiv-vaule("max-width", 561, 150, 1);

        @include media ("<tablet") {
            text-align: center;
            min-height: 640px;
            max-width: 100%;
            @include adaptiv-vaule("padding-top", 700, 330, 1);
        }

        @include media ("<phone") {
            max-width: 100%;
        }
    }

    .title {
        font-size: 22px;
        margin-bottom: 15px;
        line-height: 25 / 22 * 100%;
        font-weight: 500;
        color: $gray;

        @include media ("<desktop") {
            margin-bottom: 5px;
            font-size: 16px;
        }

        @include media ("<phone") {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }

    .guarantee-headline {
        margin-bottom: 15px;

        @include media ("<desktop") {
            margin-bottom: 30px;
            width: 300px;
        }

        @include media ("<tablet") {
            margin-bottom: 20px;
            width: 100%;
        }

        @include media ("<phone") {
            margin-bottom: 30px;
        }
    }

    .guarantee-description {
        margin-bottom: 30px;
        line-height: 21 / 18 * 100%;
        font-size: 18px;

        @include media ("<desktop") {
            margin-bottom: 60px;
        }

        @include media ("<tablet") {
            margin-bottom: 30px;
        }

        @include media ("<phone") {
            font-size: 14px;
        }
    }

    .btn {
        margin-bottom: 0;
    }

    .img-wrap {

        img {
            position: absolute;
            top: -160px;
            left: 0;
            @include adaptiv-vaule("width", 475, 200, 1);
            @include adaptiv-vaule("height", 820, 350, 1);
            z-index: 2;

            @include media ("<widescreen") {
                top: -170px;
            }

            @include media ("<desktop") {
                top: -100px;
            }

            @include media ("<tablet") {
                top: -70px;
                left: 50%;
                transform: translate(-50%)
            }

            @include media ("<phone") {
                top: -50px;
            }

            @include media ("<325px") {
                top: -30px;
            }
        }
    }
}

.detail {
    padding-top: 100px;
    @include adaptiv-vaule("padding-bottom", 200, 40, 1);
    position: relative;

    @include media ("<desktop") {
        padding-top: 30px;
    }

    .content {
        display: flex;

        @include media ("<desktop") {
            flex-direction: column;
        }
    }

    .text {
        max-width: 400px;
        margin-right: 50px;

        @include media ("<widescreen") {
            max-width: 300px;
        }

        @include media ("<desktop") {
            text-align: center;
            margin: 0 auto;
            max-width: 100%;
        }

        @include media ("<phone") {
            margin: 0;
        }
    }

    .title {
        margin-bottom: 15px;
        font-size: 22px;
        line-height: 25 / 22 * 100%;
        font-weight: 500;
        color: $gray;



        @include media ("<desktop") {
            margin-bottom: 30px;
            font-size: 16px
        }

        @include media ("<tablet") {
            margin-bottom: 20px;
        }

        @include media ("<phone") {
            font-size: 15px;
        }
    }

    .detail-headline {
        margin-bottom: 20px;

        @include media ("<widescreen") {
            font-size: 36px;
        }

        @include media ("<desktop") {
            margin-bottom: 35px;
        }

        @include media ("<tablet") {
            margin-bottom: 25px;
        }
    }

    .detail-description {
        max-width: 374px;
        margin-bottom: 50px;
        font-size: 18px;

        @include media ("<desktop") {
            margin-bottom: 30px;
            max-width: 630px;
        }

        @include media ("<phone") {
            font-size: 14px;
        }
    }

    .btn-wrap {

        @include media ("<tablet") {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .btn {

        @include media ("<tablet") {
            max-width: 270px;
        }

        &:first-child {
            margin-right: 50px;
            margin-bottom: 30px;

            @include media ("<tablet") {
                margin-right: 0;
            }

        }

        &:last-child {
            margin-bottom: 95px;
        }
    }

    .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 65%;

        @include media ("<desktop") {
            width: 100%;
        }
    }

    .icon-card {
        max-width: 350px;
        margin-bottom: 85px;

        @include media ("<widescreen") {
            max-width: 300px;
            margin-bottom: 60px;
        }

        @include media ("<desktop") {
            max-width: 350px;
        }

        @include media ("<tablet") {
            max-width: 100%;
            margin-bottom: 65px;
        }

        img {
            margin-bottom: 30px;
        }

        h3 {
            margin-bottom: 25px;

            @include media ("<phone") {
                margin-bottom: 20px;
            }
        }

        p {
            font-size: 18px;
            max-width: 350px;

            @include media ("<tablet") {
                max-width: 100%;
            }
        }
    }
}


.seo {
    position: relative;
    transform: skewY(-5deg);
    background-color: $white;
    margin: 55px 0;

    @include media ("<tablet_bg") {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 0;
        border: 10px solid transparent;
        border-left: 100vw solid $violet;
        border-bottom: 0px solid $violet;
    }

    .cont {
        overflow: hidden;
    }

    .content {
        transform: skewY(5deg);
        padding: 100px 0;

        @include media ("<tablet_bg") {
            padding: 50px 0 100px 0;
        }

        @include media ("<phone") {
            padding: 25px 0 50px 0;
        }
    }

    .text {
        width: 100%;
        max-width: 390px;

        @include media ("<tablet-max-2") {
            max-width: 350px;
        }

        @include media ("<tablet_bg") {
            max-width: 80%;
            text-align: center;
            margin: 0 auto;
        }

        @include media ("<phone") {
            max-width: 100%;
        }
    }

    .title {
        margin-bottom: 5px;
        font-size: 16px;

        @include media ("<tablet") {
            margin-bottom: 20px;
        }
    }

    .seo-headline {
        color: $black;
        margin-bottom: 20px;

        @include media ("<desktop") {
            margin-bottom: 30px;
        }

        @include media ("<phone") {
            margin-bottom: 20px;
        }
    }

    .seo-description {
        @include adaptiv-vaule("margin-bottom", 60, 30, 1);
        font-size: 18px;
    }

    .btn {
        margin: 0;
    }

    .form {
        padding: 0;
        background: transparent;

        @include media ("<tablet_bg") {
            margin: 0 auto;
            max-width: 50%;
        }

        @include media ("<tablet") {
            max-width: 80%;
        }

        @include media ("<phone") {
            max-width: 100%;
        }
    }

    .seo-name {
        border: none;
        border-bottom: 1px solid #B3B3B3;
        margin-bottom: 55px;
        color: $black;

        &::placeholder {
            color: $gray;
            font-size: 18px;
            line-height: 21 / 18 * 100%;
            padding: 0 5px;
        }

        @include media ("<tablet_bg") {
            margin-bottom: 40px;
            text-align: center;
            width: 100%;
        }

        @include media ("<phone") {
            margin-bottom: 40px;
            text-align: center;
            width: 80%;
        }
    }

    .form-btn-seo {
        border: none;

        @include media ("<tablet_bg") {
            margin: 0 auto;
        }
    }

    .img-wrap {
        position: absolute;
        top: 0;
        right: -20px;
        width: 100%;
        height: 115%;
        max-width: 45%;

        @include media ("<tablet_bg") {
            position: relative;
            right: 0;
            height: 600px;
            width: 120%;
            max-width: 120%;
        }

        @include media ("<phone") {
            height: 380px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .img-cover-wrap {
        position: absolute;
        top: -280px;
        right: 0;
        width: 100%;
        max-width: 830px;
        height: auto;

        @include media ("<1600px") {
            top: -160px;
            max-width: 700px;
        }

        @include media ("<1300px") {
            top: -70px;
            max-width: 600px;
        }

        @include media ("<1200px") {
            top: 0;
            right: -95px;
        }

        @include media ("<tablet_bg") {
            right: auto;
            left: 50%;
            transform: translate(-50%);
        }

        @include media ("<phone") {
            top: -25px;
            left: 60%;
            right: auto;
            width: 400px;
            max-width: 400px;
            transform: translate(-50%);
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: -88px;
            width: 480px;
            height: 50px;
            display: none;
            background-color: $white;

            @include media ("<phone") {
                display: block;
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

/* ------------------------------------Local Seo--------------------------- */

.title-text {
    text-align: center;
    margin: 140px 0 60px 0;

    @include media ("<widescreen") {
        margin: 65px 0 30px 0;
    }

    @include media ("<tablet") {
        margin: 50px 0 0 0;
    }
}

.headline {
    margin-bottom: 20px;

    @include media ("<widescreen") {
        margin-bottom: 40px;
    }

    @include media ("<tablet") {
        margin-bottom: 25px;
    }
}

.description {
    max-width: 840px;
    margin: 0 auto;
    font-size: 18px;

    @include media ("<tablet") {
        font-size: 14px;
    }
}

.general {
    font-size: 18px;
    max-width: 750px;
    padding-bottom: 50px;
    margin: 0 auto;

    @include media ("<desktop") {
        max-width: 400px;
    }

    @include media ("<tablet") {
        max-width: 350px;
        padding-bottom: 35px;
    }

    @include media ("<phone") {
        font-size: 14px;
        max-width: 100%;
    }
}

.bottom-text {
    text-align: center;
}

.local {
    position: relative;
    padding-bottom: 180px;

    @include media ("<widescreen") {
        padding-bottom: 130px;
    }

    @include media ("<tablet") {
        padding-bottom: 80px;
    }



    .main-text {
        display: flex;
        position: relative;
        align-items: center;
        margin-bottom: 100px;

        @include media ("<desktop") {
            flex-direction: column;
            align-items: center;
        }
    }

    .img {
        width: 50%;
        position: relative;
        z-index: 3;
        padding: 40px 20px 0 0;
        min-width: 300px;
        min-height: 380px;
        max-height: 850px;

        @include media ("<desktop") {
            z-index: 0;
            padding: 0;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .text {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        @include media ("<tablet") {
            width: 100%;
        }
    }

    .block-text,
    .sub-text {
        p {
            max-width: 500px;
            font-size: 20px;
            margin: 0 auto;

            @include media ("<widescreen") {
                font-size: 16px;
                max-width: 500px;
            }

            @include media ("<desktop") {
                text-align: center;
            }

            @include media ("<tablet") {
                font-size: 14px;
                max-width: 350px;
            }

            @include media ("<phone") {
                max-width: 300px;
            }
        }
    }

    .block-text {
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        transform: skewY(-5deg);
        background: #1D1D1D;
        padding: 100px 0;
        width: 100vw;
        margin: 50px 0 100px 0;

        @include media ("<widescreen") {
            margin: 20px 0 50px 0;
            padding: 70px 0;
        }

        @include media ("<desktop") {
            padding: 50px 0;
            margin: -65px 0 50px 0;
        }

        @include media ("<tablet") {
            margin: -65px 0 30px 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 5px solid transparent;
            border-right: 100vw solid $orange;
            border-top: 0px solid $orange;
        }

        p {
            transform: skewY(5deg);
        }
    }

    .sub-text {
        width: 100%;

        p:first-child {
            margin-bottom: 50px;

            @include media ("<desktop") {
                margin-bottom: 65px;
            }

            @include media ("<phone") {
                margin-bottom: 20px;
            }
        }
    }

    .btn {
        margin: 0;
    }
}

.tell-about {
    position: relative;
    padding-bottom: 180px;

    .content {
        display: flex;
        gap: 150px;

        @include media ("<widescreen") {
            flex-direction: column;
            align-items: center;
        }

        @include media ("<tablet") {
            gap: 240px;
        }

        @include media ("<phone") {
            gap: 150px;
        }
    }

    .project {
        width: 40%;

        @include media ("<widescreen") {
            width: 100%;
            text-align: center;
        }

        @include media ("<tablet") {
            padding-bottom: 50px;
        }
    }

    .title {
        font-weight: 500;
        font-size: 22px;
        color: $gray;
        line-height: 25 / 22 * 100%;
        margin-bottom: 15px;

        @include media ("<widescreen") {
            font-size: 16px;
            margin-bottom: 20px;
        }

        @include media ("<phone") {
            font-size: 15px;
        }
    }

    .head-title {
        line-height: 74 / 64 * 100%;
        margin-bottom: 30px;
        color: $white;

        @include media ("<widescreen") {
            margin-bottom: 20px;
        }

        @include media ("<phone") {
            font-size: 36px;
        }
    }

    .decription {
        font-weight: 500;
        font-size: 22px;
        line-height: 25 / 22 * 100%;
        margin-bottom: 100px;

        @include media ("<widescreen") {
            font-size: 18px;
            margin-bottom: 70px;
        }

        @include media ("<phone") {
            font-size: 14px;
            margin-bottom: 40px;
        }
    }

    .subline {
        font-size: 48px;
        line-height: 55 / 48 * 100%;
        margin-bottom: 30px;

        @include media ("<widescreen") {
            font-size: 32px;
            margin-bottom: 25px;
        }
    }

    .icons {

        @include media ("<widescreen") {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .card {
        display: flex;
        align-items: flex-start;
        min-height: 90px;
        margin: 0 0 45px 0;

        @include media ("<widescreen") {
            max-width: 40%;
        }

        @include media ("<tablet") {
            flex-direction: column;
            align-items: center;
            max-width: 100%;
        }
    }

    .card-img {
        position: relative;
        width: 100%;
        max-width: 90px;
        min-height: 90px;
        margin: 0 20px 0 0;

        @include media ("<tablet") {
            margin: 0 0 20px 0;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-text {
        color: $gray;

        @include media ("<widescreen") {
            text-align: start;
        }

        @include media ("<tablet") {
            text-align: center;
        }

        h4 {
            color: $gray;
            font-weight: 700;
            font-size: 18px;
            line-height: 21 / 18 * 100%;
            margin-bottom: 5px;
        }

        span {
            font-style: italic;
            font-weight: 300;
            font-size: 12px;
            line-height: 14 / 12 * 100%;
        }

        p {
            margin-top: 5px;
            font-size: 18px;
            line-height: 21 / 18 * 100%;

            @include media ("<widescreen") {
                font-size: 14px;
            }

            @include media ("<tablet") {
                font-size: 18px;
            }
        }
    }

    .tab {
        position: relative;
    }

    .btn-wrap {
        text-align: center;
    }

    .btn {
        border: none;
    }

    .bg-img {
        position: absolute;
        top: 70px;
        right: -26vh;
        z-index: 0;

        @include media ("<widescreen") {
            right: -41vh;
        }

        @include media ("<desktop") {
            right: -30vh;
        }

        @include media ("<tablet") {
            top: -250px;
            right: 50%;
            transform: translate(50%);
            max-width: 400px;
            max-height: 500px;
        }

        @include media ("<phone") {
            top: -140px;
            max-width: 250px;
            max-height: 300px;
        }
    }
}


form {
    max-width: 515px;
    background: linear-gradient(180deg, rgba(177, 168, 168, 0.6) 0%, rgba(23, 23, 23, 0.228) 100%);
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(55px);
    border-radius: 5px;
    padding: 50px 50px 80px 50px;
    position: relative;
    z-index: 1;

    @include media ("<tablet") {
        max-width: 100%;
        margin-top: 50px;
    }

    @include media ("<xs-phone") {
        padding: 25px 15px 15px 15px;
    }
}

input,
textarea {
    border: none;
    background: transparent;
    color: $white;
    border-bottom: 1px solid $white;
    padding: 10px 5px;
    transition: all 0.3s ease;

    &::placeholder {
        color: $white;
        font-size: 18px;
        line-height: 21 / 18 * 100%;
    }

    &:focus {
        border: none;

    }
}

.url {
    width: 100%;
    margin-bottom: 60px;

    @include media ("<phone") {
        margin-bottom: 30px;
    }
}

.data {
    width: 45%;
    margin-bottom: 50px;

    @include media ("<phone") {
        width: 100%;
        margin-bottom: 30px;
    }
}

.gap {
    margin-right: 20px;

    @include media ("<phone") {
        margin-right: 0;
    }
}

textarea {
    margin: 20px 0 15px 0;
    width: 100%;
    height: 150px;

    @include media ("<xs-phone") {
        margin: 0 0 15px 0;
    }
}

.sub-info {
    font-weight: 500;
    font-size: 9px;
    line-height: 10 / 9 * 100%;
    color: #C5C5C5;
    margin-bottom: 25px;
}

.form-btn {
    border: none;
    display: block;
    margin: 0 auto;

    @include media ("<xs-phone") {
        min-width: 100%;
    }
}

/* ---------------------- SEO Guarantee -------------------- */


.seo-guarantee {
    position: relative;
    padding-bottom: 170px;

    @include media ("<widescreen") {
        padding-bottom: 115px;
    }

    @include media ("<tablet") {
        padding-bottom: 65px;
    }

    .title-text {
        padding-bottom: 65px;

        @include media ("<widescreen") {
            padding-bottom: 100px;
        }

        @include media ("<tablet") {
            padding-bottom: 25px;
        }
    }

    .block-text {
        display: flex;
        align-items: center;
        padding-bottom: 120px;

        @include media ("<widescreen") {
            padding-bottom: 130px;
        }

        @include media ("<minscreen") {
            flex-direction: column-reverse;
        }

        @include media ("<tablet") {
            padding-bottom: 50px;
        }

    }

    .main-text {
        max-width: 510px;
        margin-right: 100px;

        @include media ("<widescreen") {
            margin-right: 50px;
            max-width: 400px;
        }

        @include media ("<minscreen") {
            margin-right: 0;
            text-align: center;
        }

        @include media ("<phone") {
            max-width: 100%;
        }

        p {
            font-size: 20px;
            line-height: 23 / 20 * 100%;

            @include media ("<widescreen") {
                font-size: 16px;
            }

            @include media ("<tablet") {
                font-size: 14px;
            }
        }

        p:nth-child(1) {
            margin-bottom: 70px;

            @include media ("<widescreen") {
                margin-bottom: 65px;
            }

            @include media ("<minscreen") {
                max-width: 400px;
                margin: 0 auto;
                transform: skewY(0deg);
            }

            @include media ("<phone") {
                max-width: 100%;
                padding: 0 20px;
            }
        }

        p:nth-child(2) {
            margin-bottom: 70px;

            @include media ("<widescreen") {
                margin-bottom: 65px;
            }

            @include media ("<tablet") {
                margin-bottom: 35px;
            }
        }
    }

    .bg-text {

        @include media ("<minscreen") {
            background: #1D1D1D;
            min-height: 250px;
            transform: skewY(-5deg);
            width: 101vw;
            display: flex;
            align-items: center;
            position: relative;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            margin-bottom: 40px;

            p {
                padding: 25px 0;
            }


            @include media ("<phone") {
                min-height: 200px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border: 3px solid transparent;
                border-right: 100vw solid $orange;
                border-top: 0px solid $orange;
            }
        }
    }

    .main-bg {
        position: relative;
        z-index: 1;

        @include media ("<minscreen") {
            max-width: 450px;
            max-height: 540px;
        }
    }

    .block {
        background: #1D1D1D;
        transform: skewY(-5deg);
        min-height: 330px;
        min-width: 700px;
        position: absolute;
        top: 60px;
        right: -250px;
        z-index: -1;
        overflow: hidden;

        @include media (">1720px") {
            right: -350px;
        }

        @include media ("<minscreen") {
            display: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border: 10px solid transparent;
            border-right: 100vw solid $orange;
            border-top: 0px solid $orange;
        }
    }
}


/* ----------------------------------Price----------------------- */


.package {
    position: relative;
    padding-top: 90px;
    padding-bottom: 100px;

    @include media ("<widescreen") {
        padding-top: 120px;
    }

    @include media ("<minscreen") {
        padding-top: 50px;
    }

    @include media ("<phone") {
        padding-bottom: 200px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media ("<minscreen") {
            flex-direction: column-reverse;
        }
    }

    .pack-img {
        margin-bottom: -350px;

        img {
            width: 100%;
            height: 100%;
        }

        @include media ("<phone") {
            margin-bottom: -300px;
            min-width: 100vw;
        }
    }

    .pack-text {
        text-align: start;
        max-width: 530px;

        @include media ("<minscreen") {
            text-align: center;
            max-width: 750px;
        }
    }

    .pack-headline {
        margin-bottom: 50px;

        @include media ("<widescreen") {
            margin-bottom: 20px;
        }

        @include media ("<minscreen") {
            margin-bottom: 15px;
        }
    }

    .pack-description {
        max-width: 400px;
        font-size: 18px;

        @include media ("<widescreen") {
            font-size: 15px;
        }

        @include media ("<minscreen") {
            margin: 0 auto;
            margin-bottom: 35px;
            max-width: 100%;
        }

        @include media ("<phone") {
            font-size: 14px;
        }
    }
}

.tariff {
    position: relative;
    background: #1D1D1D;
    transform: skewY(-5deg);
    padding: 80px 0;


    @include media ("<widescreen") {
        padding: 70px 0;
    }

    @include media ("<phone") {
        padding: 50px 0;
    }

    .cont {
        max-width: 1465px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 5px solid transparent;
        border-left: 100vw solid $orange;
        border-top: 0px solid $orange;
    }

    .content {
        transform: skewY(5deg);
        display: flex;
        justify-content: space-between;

        @include media ("<widescreen") {
            justify-content: space-around;
        }

        @include media ("<1025px") {
            justify-content: space-between;
        }

        @include media ("<tablet-max") {
            flex-direction: column;
            align-items: center;
        }
    }

    .tariff-price {
        max-width: 440px;
        text-align: center;

        @include media ("<highscreen") {
            max-width: 380px;
        }

        @include media ("<widescreen") {
            max-width: 270px;
        }

        @include media ("<tablet-max") {
            max-width: 440px;
        }

        @include media ("<tablet") {
            max-width: 380px;
        }

        @include media ("<phone") {
            max-width: 270px;
        }
    }

    .premium {

        @include media ("<tablet-max") {
            order: 2;
            margin-bottom: 80px;
        }

        @include media ("<phone") {
            margin-bottom: 40px;
        }
    }

    .platinum {

        @include media ("<tablet-max") {
            order: 3;
        }
    }

    .price {
        width: 190px;
        height: 190px;
        margin: 0 auto;
        background: $white;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: -90px;
        position: relative;
        z-index: 1;
        box-shadow: 0px 3.66383px 13.7394px rgba(0, 0, 0, 0.25);

        @include media ("<highscreen") {
            width: 150px;
            height: 150px;
            ;
            margin-bottom: -80px;
        }

        @include media ("<widescreen") {
            width: 115px;
            height: 115px;
            margin-bottom: -55px;
        }

        @include media ("<tablet-max") {
            width: 190px;
            height: 190px;
            margin-bottom: -90px;
        }

        @include media ("<tablet") {
            width: 150px;
            height: 150px;

            margin-bottom: -80px;
        }

        @include media ("<phone") {
            width: 115px;
            height: 115px;
            margin-bottom: -55px;
        }

        p {
            color: $orange;
            font-weight: 700;
            font-size: 50px;
            line-height: 58 / 50 * 100%;

            @include media ("<widescreen") {
                font-size: 33px;
            }
        }

        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 21 / 18 * 100%;
            color: #D0D0D0;

            @include media ("<widescreen") {
                font-size: 12px;
            }
        }
    }

    .name {
        border-radius: 36px 36px 440px 440px / 36px 36px 310px 310px;
        background: $orange;
        min-height: 310px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        box-shadow: 0px 3.66383px 18.3192px rgba(0, 0, 0, 0.55);
        padding: 100px 0 60px 0;

        @include media ("<highscreen") {
            min-height: 280px;
        }

        @include media ("<widescreen") {
            min-height: 190px;
            margin-bottom: 20px;
            padding: 70px 0 40px 0;
        }

        @include media ("<tablet-max") {
            min-height: 310px;
        }

        @include media ("<tablet") {
            min-height: 280px;
        }

        @include media ("<phone") {
            min-height: 190px;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 55px;
            line-height: 63 / 55 * 100%;
            margin-bottom: 20px;

            @include media ("<highscreen") {
                margin-bottom: 15px;
            }

            @include media ("<widescreen") {
                font-size: 33px;
            }

            @include media ("<tablet-max") {
                font-size: 55px;
            }

            @include media ("<phone") {
                font-size: 33px;
            }
        }

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 15 / 12 * 100%;
            color: $white;
            padding: 0 75px;

            @include media ("<widescreen") {
                padding: 0 45px;
                font-size: 12px;
            }

            @include media ("<tablet-max") {
                font-size: 14px;
            }

            @include media ("<phone") {
                font-size: 12px;
            }
        }
    }

    .list {
        border-radius: 2px;
        margin: 0 auto;
        margin-top: -200px;
        width: 90%;
        background: $white;
        padding: 240px 0 50px 0;

        @include media ("<widescreen") {
            padding: 200px 0 50px 0;
        }
    }

    .point {
        font-weight: 700;
        font-size: 16px;
        line-height: 19 / 16 * 100%;
        padding: 25px 72px;

        @include media ("<widescreen") {
            font-size: 14px;
            padding: 15px;
        }

        @include media ("<tablet-max") {
            padding: 25px 35px;
            font-size: 16px;
        }

        @include media ("<phone") {
            padding: 15px 10px;
            font-size: 14px;
        }

        &:nth-child(odd) {
            background: #E2E0E0;
        }
    }

    .btn {
        margin-top: -20px;
        font-size: 16px;

        @include media ("<widescreen") {
            min-width: 244px;
        }

        @include media ("<tablet-max") {
            min-width: 270px;
        }

        @include media ("<phone") {
            min-width: 243px;
            text-align: center;
        }
    }

    .pro {
        min-width: 480px;
        text-align: center;
        margin-top: -40px;

        @include media ("<highscreen") {
            min-width: 420px;
            margin-top: -30px;
        }

        @include media ("<widescreen") {
            min-width: 330px;
        }

        @include media ("<tablet-max") {
            order: 1;
            margin-bottom: 80px;
            min-width: 480px;
        }

        @include media ("<tablet") {
            min-width: 420px;
        }

        @include media ("<phone") {
            min-width: 300px;
        }

        .price {
            margin-bottom: -100px;
            width: 200px;
            height: 200px;


            @include media ("<highscreen") {
                width: 170px;
                height: 170px;

                margin-bottom: -85px;
            }

            @include media ("<widescreen") {
                width: 130px;
                height: 130px;
                margin-bottom: -60px;
            }

            @include media ("<tablet-max") {
                margin-bottom: -100px;
                width: 200px;
                height: 200px;
            }

            @include media ("<tablet") {
                width: 170px;
                height: 170px;
                margin-bottom: -85px;
            }

            @include media ("<phone") {
                width: 130px;
                height: 130px;
                margin-bottom: -60px;
            }
        }

        .tax {
            color: $violet;
            font-size: 55px;

            @include media ("<widescreen") {
                font-size: 33px;
            }

            @include media ("<tablet-max") {
                font-size: 55px;
            }

            @include media ("<phone") {
                font-size: 33px;
            }
        }

        .price-desk {
            font-size: 20px;

            @include media ("<widescreen") {
                font-size: 14px;
            }

            @include media ("<tablet-max") {
                font-size: 20px;
            }

            @include media ("<phone") {
                font-size: 14px;
            }
        }

        .name {
            background: $violet;
            min-height: 340px;

            @include media ("<highscreen") {
                min-height: 280px;
            }

            @include media ("<widescreen") {
                min-height: 200px;
            }

            @include media ("<tablet-max") {
                min-height: 340px;
            }

            @include media ("<tablet") {
                min-height: 280px;
            }

            @include media ("<phone") {
                min-height: 200px;
            }
        }

        .name-description {
            padding: 0 85px;

            @include media ("<widescreen") {
                padding: 0 50px;
            }
        }
    }
}



.add {
    position: relative;
    padding: 130px 0 200px 0;
    @include adaptiv-vaule("padding-top", 130, 50, 1);
    @include adaptiv-vaule("padding-bottom", 200, 100, 1);

    p {
        max-width: 770px;
        margin: 0 auto;
        margin-bottom: 50px;
        text-align: center;
        font-size: 18px;
        line-height: 21 / 18 * 100%;

        @include media ("<tablet") {
            font-size: 14px;
            margin-bottom: 35px;
        }
    }

    .btn-wrap {
        text-align: center;

        @include media ("<tablet") {
            display: flex;
            flex-direction: column;
            max-width: 270px;
            margin: 0 auto;
        }
    }

    .btn {

        &:first-child {
            margin: 0 80px 0 0;

            @include media ("<tablet") {
                margin: 0 0 30px 0;
            }
        }
    }
}


/* ------------------busines----------------------- */

.local-busines {
    position: relative;
    margin-bottom: 150px;

    @include media("<tablet") {
        margin-bottom: 80px;
    }

    @include media("<phone") {
        margin-bottom: 50px;
    }

    .content {
        margin-bottom: 200px;

        @include media("<widescreen") {
            margin-bottom: 130px;
        }

        @include media("<tablet") {
            margin-bottom: 60px;
        }
    }

    .busines-description {
        max-width: 700px;

        @include media("<widescreen") {
            max-width: 800px;
        }

        @include media("<tablet") {
            max-width: 100%;
        }
    }

    .title-text {
        margin-bottom: 35px;
    }

    .main-text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 150px;

        @include media("<widescreen") {
            margin-bottom: 100px;
        }

        @include media("<tablet") {
            flex-direction: column;
            margin-bottom: 60px;
        }
    }

    .img-busines {


        @include media("<tablet") {
            margin-bottom: 40px;
            max-width: 400px;
            max-height: 450px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .text {
        max-width: 510px;
        margin-left: 100px;

        @include media("<tablet-max") {
            max-width: 400px;
            margin-left: 60px;
        }

        @include media("<tablet") {
            max-width: 600px;
            text-align: center;
            margin-left: 0;
        }

        p {
            font-size: 20px;
            line-height: 23 / 20 * 100%;


            @include media("<1025px") {
                font-size: 15px;
            }

            @include media("<phone") {
                font-size: 14px;
            }

            &:not(:last-child) {
                margin-bottom: 90px;

                @include media("<1025px") {
                    margin-bottom: 40px;
                }

                @include media("<tablet") {
                    margin-bottom: 30px;
                }
            }
        }
    }
}

.graph {
    text-align: center;

    img {
        object-fit: cover;
        width: 100%;
        height: auto;
    }
}

.guaranteed-profitability {
    position: relative;
    background: #1D1D1D;
    transform: skewY(-5deg);
    padding: 100px 0 250px 0;

    @include media ("<widescreen") {
        padding: 25px 0 60px 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 10px solid transparent;
        border-left: 100vw solid $orange;
        border-top: 0px solid $orange;
    }

    .content {
        transform: skewY(5deg);
        display: flex;
        justify-content: flex-end;
        align-items: center;


        @include media ("<minscreen") {
            flex-direction: column;
            justify-content: center;
        }
    }

    .prof-img {
        max-width: 650px;
        max-height: 710px;
        position: absolute;
        top: -200px;
        left: -80px;

        @include media ("<widescreen") {
            position: relative;
            top: 0;
            left: 0;
            margin-right: 50px;
        }

        @include media ("<minscreen") {
            margin: 0 0 50px 0;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .text {
        max-width: 565px;

        @include media ("<minscreen") {
            text-align: center;
        }

        span {
            font-weight: 500;
            font-size: 22px;
            line-height: 25 / 22 * 100%;
            color: $gray;

            @include media ("<widescreen") {
                font-size: 16px;
            }

            @include media ("<phone") {
                font-size: 15px;
            }
        }

        h2 {
            margin: 15px 0;

            @include media ("<widescreen") {
                margin: 5px 0 30px 0;
            }

            @include media ("<phone") {
                margin: 20px 0 30px 0;
            }
        }

        p {
            font-size: 18px;
            margin-bottom: 30px;
            max-width: 500px;

            @include media ("<widescreen") {
                margin-bottom: 60px;
                max-width: 400px;
            }

            @include media ("<tablet") {
                font-size: 14px;
                margin-bottom: 35px;
            }
        }
    }
}

.busines-seo {
    margin: 0 0 55px 0;
}

/* ----------------------------Contact----------------- */

.contact {
    position: relative;
    padding: 150px 0 150px 0;

    @include media ("<highscreen") {
        padding: 100px 0 50px 0;
    }

    @include media ("<widescreen") {
        padding: 100px 0 100px 0;
    }

    .title {
        font-size: 48px;
        line-height: 55 / 44 * 100%;
        margin-bottom: 70px;

        @include media ("<widescreen") {
            margin-bottom: 40px;
            text-align: center;
        }

        @include media ("<tablet") {
            margin-bottom: 50px;
            font-size: 36px;
        }
    }

    .map {
        position: relative;
        margin-bottom: 30px;
        min-height: 760px;

        @include media ("<highscreen") {
            margin-bottom: 200px;
        }

        @include media ("<tablet-max-2") {
            margin-bottom: 0;
        }

        img {
            position: absolute;
            top: -5px;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            border: 10px solid transparent;
            border-right: 100vw solid $orange;
            border-top: 0px solid $orange;
            transform: rotate(-0.31deg);
        }
    }

    .link-form {
        max-width: 1530px;
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
        display: flex;

        @include media ("<tablet-max-2") {
            flex-direction: column-reverse;
            align-items: center;
            margin-top: -150px;
        }
    }

    .links {
        width: 65%;
        display: flex;
        flex-wrap: wrap;

        @include media ("<highscreen") {
            font-size: 18px;
            width: 100%;
        }

        @include media ("<widescreen") {
            width: 100%;
        }

        @include media ("<tablet-max-2") {
            flex-direction: column;
            max-width: 515px;
            margin-top: 40px;
        }

    }

    .link-wrap {
        display: flex;
        align-items: center;
        margin: 0 55px 30px 0;

        @include media ("<tablet-max-2") {
            margin: 0 0 45px 0;
            align-items: flex-start;

            &:last-child {
                margin: 0;
            }
        }
    }

    .icon-wrap {
        margin: 0px 25px 0 0;
        width: 29px;
        height: 29px;
        object-fit: contain;
    }

    .location-icon {

        @include media ("<tablet-max-2") {
            margin-top: 4px;
        }
    }

    .text {

        .name {
            margin-top: 5px;
        }
    }

    .link,
    .loc {
        font-weight: 500;
        font-size: 20px;
        color: $white;

        @include media ("<highscreen") {
            font-size: 18px;
        }
    }

    .link {

        &:hover {
            color: #7E7D7D;
            transition: all 0.4s ease;
        }
    }

    .name {
        font-weight: 300;
        font-size: 12px;
        line-height: 14 / 12 * 100%;
        color: #7E7D7D;
    }

    .form-contact {
        position: absolute;
        bottom: 170px;
        right: 50px;

        @include media ("<highscreen") {
            bottom: 310px;
        }

        @include media ("<widescreen") {
            top: 420px;
        }

        @include media ("<tablet-max-2") {
            position: static;
        }
    }
}


/* -------------------------Studies-------------------- */

.studies {
    position: relative;
    padding: 140px 0 0 0;

    @include media ("<widescreen") {
        padding: 100px 0 0 0;
    }

    @include media ("<tablet") {
        padding: 60px 0 0 0;
    }

    .title-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 100px 0;

        @include media ("<tablet-max") {
            flex-direction: column-reverse;
        }

        @include media ("<tablet") {
            margin: 0 0 60px 0;
        }
    }

    .text {
        width: 45%;
        text-align: start;

        @include media ("<tablet-max") {
            text-align: center;
            width: 100%;
        }
    }

    .sub-title {
        color: $gray;
        font-weight: 500;
        font-size: 22px;
        line-height: 25 / 22 * 100%;

        @include media ("<widescreen") {
            font-size: 18px;
        }

        @include media ("<tablet") {
            font-size: 15px;
        }
    }

    .headline {
        margin: 15px 0 40px 0;

        @include media ("<widescreen") {
            margin: 20px 0 50px 0;
        }

        @include media ("<tablet") {
            margin: 20px 0 40px 0;
        }
    }

    .description {
        font-size: 18px;
        line-height: 21 / 18 * 100%;
        color: $gray;
        margin-bottom: 75px;

        @include media ("<widescreen") {
            font-size: 15px;
            margin-bottom: 90px;
        }

        @include media ("<tablet") {
            font-size: 14px;
            margin-bottom: 45px;
        }
    }

    .links-app {
        display: flex;
    }

    .link-app {
        transition: 0.5s;

        &:hover {
            transform: scale(1.2);
            transition: 0.5s;
        }


        @include media ("<phone") {

            img {
                max-width: 130px;
                max-height: 45px;
            }
        }

        &:first-child {
            margin: 0 30px 0 0;

            @include media ("<phone") {
                margin: 0 5px 0 0;
            }
        }

    }

    .title-img {
        width: 54%;

        @include media ("<tablet-max") {
            margin-bottom: 60px;
            width: 100%;
        }
    }
}

.cards {
    position: relative;

    .cont {

        @include media ("<phone") {
            padding: 0;
        }
    }

    .info-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        @include media ("<tablet") {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    .card {
        width: 49%;
        margin-bottom: 100px;
        min-height: 100%;
        display: flex;
        flex-direction: column;

        @include media ("<widescreen") {
            margin-bottom: 50px;
        }

        @include media ("<tablet") {
            margin-bottom: 40px;
            width: 100%;
        }
    }

    .info {
        flex: 1 1 auto;
    }

    .img-card {
        width: 100%;
        height: 385px;
        margin-bottom: 25px;
        position: relative;

        @include media ("<widescreen") {
            margin-bottom: 15px;
        }

        @include media ("<tablet") {
            margin: 0 0 10px 0;
            max-height: 200px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .card-title {
        font-weight: 700;
        font-size: 25px;
        line-height: 29 / 25 * 100%;
        margin-bottom: 15px;

        @include media ("<widescreen") {
            margin-bottom: 10px;
            font-size: 16px;
        }

        @include media ("<tablet") {
            margin-bottom: 10px;
            font-size: 15px;
        }

        @include media ("<phone") {
            padding: 0 15px;
        }
    }

    .card-description {
        font-weight: 300;
        font-size: 18px;
        line-height: 21 / 18 * 100%;
        margin-bottom: 25px;

        @include media ("<1150px") {
            margin-bottom: 15px;
            font-size: 12px;
        }

        @include media ("<tablet") {
            margin-bottom: 35px;
        }

        @include media ("<phone") {
            padding: 0 15px;
        }
    }

}

.download {
    position: relative;
    padding: 0 0 80px 0;

    .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 55 / 48 *100%;
        margin-bottom: 110px;
        text-align: center;
        padding: 0 15px;

        @include media ("<widescreen") {
            margin-bottom: 80px;
            font-size: 36px;
        }

        @include media ("<tablet") {
            margin-bottom: 70px;
        }
    }

    .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .icon {
        width: 30%;
        margin-bottom: 150px;
        padding: 0 30px;

        @include media ("<widescreen") {
            width: 50%;
        }

        @include media ("<tablet") {
            margin-bottom: 115px;
            width: 100%;

            &:last-child {
                margin-bottom: 95px;
            }
        }
    }

    .download-btn {

        img {
            margin-bottom: 25px;
            width: 67px;
            height: 68px;
        }
    }

    .icon-title {
        font-weight: 700;
        font-size: 25px;
        line-height: 29 / 25 * 100%;
        color: $white;
    }

    .download-text {
        margin: 50px 0 80px 0;
        display: flex;
        justify-content: space-around;
        padding: 0 15px;

        @include media ("<widescreen") {
            margin: 0 0 60px 0
        }

        @include media ("<tablet") {
            flex-direction: column;
        }
    }

    .btn-wrap {
        width: 34%;
        display: flex;
        flex-direction: column;
        min-height: 100%;

        @include media ("<tablet") {
            width: 100%;
            text-align: center;

            &:not(:last-child) {
                margin-bottom: 50px;
            }
        }
    }

    .btn-text {
        flex: 1 1 auto;
    }

    .text {
        font-size: 20px;
        line-height: 23/ 20 * 100%;
        color: $gray;
        margin-bottom: 90px;


        @include media ("<widescreen") {
            margin-bottom: 65px;
            font-size: 15px;
        }

        @include media ("<tablet") {
            margin-bottom: 40px;
            font-size: 14px;
        }
    }
}


/* -------------------------Cases----------------------- */

.case {
    padding: 170px 0 45px 0;
    position: relative;

    .cont {

        @include media ("<tablet") {
            padding: 0;
        }
    }

    @include media ("<minscreen") {
        padding: 150px 0 0 0;
    }

    @include media ("<tablet") {
        padding: 40px 0 10px 0;
    }

    .cont {
        @include media ("<minscreen") {
            padding: 0;
        }
    }

    .title {
        font-size: 55px;
        line-height: 115%;
        margin-bottom: 75px;

        @include media ("<minscreen") {
            margin-bottom: 60px;
            padding: 0 15px;
        }

        @include media ("<tablet") {
            margin-bottom: 40px;
            font-size: 30px;
        }

        span {
            color: $violet;
        }
    }

    .case-img {

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include media ("<phone") {
                min-height: 185px;
            }
        }
    }
}

.project-type {
    position: relative;
    padding: 0 0 200px 0;

    .cont {

        @include media ("<tablet") {
            padding: 0;
        }
    }

    @include media ("<minscreen") {
        padding: 0 0 150px 0;
    }

    @include media ("<tablet") {
        padding: 0 0 100px 0;
    }

    .container {

        @include media ("<tablet") {
            padding: 0;
        }
    }

    .text-wrap {
        padding: 35px 0 70px 0;

        @include media ("<tablet") {
            padding: 20px 15px 50px 15px;
        }

        p {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            margin-left: 40px;
            padding: 0;

            @include media ("<tablet") {
                margin-left: 20px;
            }
        }

        li {
            font-weight: 300;
            font-size: 20px;
            line-height: 24 / 20 * 100%;
            color: $white;
            list-style: inside;
            margin-bottom: 5px;

            &::marker {
                color: $white;
            }

            @include media ("<minscreen") {
                font-size: 18px;
            }

            @include media ("<tablet") {
                font-size: 16px;
            }
        }
    }

    .type-content {
        display: flex;
        padding: 35px 0;

        @include media ("<tablet") {
            padding: 20px 0;
        }

        @include media ("<desktop") {
            flex-direction: column;
            align-items: center;
        }
    }

    h2 {
        font-size: 30px;
        line-height: 37 / 30 * 100%;
        margin-bottom: 16px;

        @include media ("<minscreen") {
            font-size: 32px;
            margin-bottom: 30px;
        }

        @include media ("<tablet") {
            font-size: 28px;
            margin-bottom: 25px;
        }
    }

    p {
        font-weight: 300;
        font-size: 20px;
        line-height: 24 / 20 * 100%;
        margin-bottom: 16px;

        @include media ("<tablet") {
            font-size: 16px;
        }
    }

    .type-img {
        position: relative;
        width: 65%;
        margin-right: 60px;

        @include media ("<minscreen") {
            margin-right: 50px;
        }

        @include media ("<desktop") {
            margin: 0 0 50px 0;
            width: 100%;
            display: flex;
            justify-content: center;
        }

        img {
            width: 100%;

            @include media (">desktop") {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .btn {
            position: relative;
            width: 100%;
            border-radius: 0;
            padding: 7px 0;
            z-index: 2;

            @include media ("<tablet") {
                padding: 12px 0;
            }

            @include media ("<phone") {
                padding: 7px 0;
            }

            img {
                width: 32px;
                height: 32px;

                @include media ("<tablet") {
                    width: 23px;
                    height: 23px;
                }

                @include media ("<phone") {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .type-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 40%;

        @include media ("<desktop") {
            width: 100%;
        }

        @include media ("<tablet") {
            padding: 0 15px;
        }

        .btn-wrap {
            text-align: center;
        }

        .btn-orange-sm {
            min-width: 100%;

            @include media ("<desktop") {
                min-width: 270px;
            }
        }
    }

    .text-block {

        h2 {
            @include media ("<minscreen") {
                margin-bottom: 16px;
            }
        }

        p {
            margin-bottom: 40px;

            @include media ("<minscreen") {
                margin-bottom: 25px;
            }

            @include media ("<tablet") {
                margin-bottom: 15px;
            }
        }
    }

    .seo-person {
        display: flex;
        margin-bottom: 25px;
        padding-top: 50px;

        @include media ("<tablet") {
            margin: 0 auto;
            margin-bottom: 25px;
        }

        @include media ("<phone") {
            margin: 0;
            margin-bottom: 30px;
        }
    }

    .img-logo {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .name {
        display: block;
        font-weight: 700;
        font-size: 18px;
        line-height: 22 / 18 * 100%;
        margin-bottom: 0;
        color: $white;

        @include media ("<tablet") {
            font-size: 18px;
        }
    }

    .sub-name {
        font-weight: 300;
        font-size: 13px;
        line-height: 16 / 13 * 100%;
        color: $white;

        @include media ("<tablet") {
            font-size: 15px;
        }
    }

    .text-img {
        height: auto;
        width: 100%;
    }

    .block-img {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        padding: 35px 0;

        @include media ("<tablet") {
            flex-direction: column;
            padding: 20px 0;
        }

    }

    .img-wrap {
        position: relative;
        width: 49.4%;
        height: 550px;
        border-radius: 3px;
        overflow: hidden;

        @include media ("<minscreen") {
            height: 450px;
            width: 49.3%;
        }

        @include media ("<tablet") {
            width: 100%;
        }

        @include media ("<phone") {
            height: 280px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .web {
        display: flex;
        padding: 35px 0;

        @include media ("<desktop") {
            flex-direction: column;
            align-items: center;
        }

        @include media ("<tablet") {
            padding: 20px 0;
        }

        @include media ("<phone") {
            width: 100%;
        }

    }

    .web-text {
        width: 46%;
        margin-right: 50px;

        @include media ("<desktop") {
            width: 100%;
            margin: 0 0 35px 0;
        }

        @include media ("<tablet") {
            padding: 0 15px;
        }

        h2 {
            margin-bottom: 30px;
        }
    }

    .img-web {
        width: 100%;
        max-width: 50%;
        border-radius: 3px;
        overflow: hidden;

        @include media ("<desktop") {
            max-width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .arrow {
        display: flex;
        justify-content: space-between;

        @include media ("<tablet") {
            padding: 0 15px;
        }

        a {
            font-weight: 700;
            font-size: 24px;
            line-height: 29 / 24 * 100%;
            text-decoration-line: underline;
            color: $white;

            @include media ("<tablet") {
                font-size: 0;
            }
        }

        img {
            vertical-align: middle;

            @include media ("<tablet") {
                width: 25px;
                height: 35px;
            }

            &:first-child {
                margin-right: 15px;
            }

            &:last-child {
                margin-left: 15px;
            }
        }
    }
}

/* ---------------------------Blog------------------------- */

.blog {
    position: relative;
    padding: 70px 0 40px 0;


    @include media ("<widescreen") {
        padding: 50px 0 130px 0;
    }

    @include media ("<tablet") {
        padding: 70px 0 60px 0;
    }

    @include media ("<phone") {
        padding: 70px 0 0 0;
    }

    .cont {

        @include media ("<tablet") {
            padding: 0;
        }
    }

    .blog-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media ("<desktop") {
            flex-direction: column;
            justify-content: center;
        }
    }

    .title {
        font-size: 55px;
        margin-right: 90px;
        width: 29%;

        @include media ("<desktop") {
            text-align: center;
            width: 100%;
            font-size: 36px;
            margin: 0 0 60px 0;
            padding: 0 15px;

        }
    }

    .blog-img {
        position: relative;
        min-height: 450px;
        width: 70%;

        @include media ("<desktop") {
            width: 100%;
        }

        @include media ("<phone") {
            display: none;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}

.blog-text {
    position: relative;
    padding: 0 0 150px 0;

    .cont {

        @include media ("<tablet") {
            padding: 0;
        }
    }

    .blog-text-content {
        margin-bottom: 100px;

        @include media ("<widescreen") {
            margin-bottom: 40px;
        }

        @include media ("<tablet") {
            margin-bottom: 60px;
        }
    }

    .text-title {

        @include media ("<phone") {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 38 / 32 * 100%;
        margin-bottom: 55px;
        text-align: center;

        @include media ("<widescreen") {
            margin-bottom: 65px;
        }

        @include media ("<tablet") {
            font-size: 18px;
            margin-bottom: 60px;
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }
    }

    .blog-text-img {
        margin-bottom: 30px;
        width: 100%;
        min-height: 470px;
        max-height: 590px;
        position: relative;

        @include media ("<widescreen") {
            margin-bottom: 40px;
        }

        @include media ("<phone") {
            margin-bottom: 60px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .description {
        max-width: 100%;
        font-weight: 300;
        font-size: 18px;
        line-height: 21 / 18 * 100%;
        margin-bottom: 15px;

        @include media ("<widescreen") {
            margin-bottom: 40px;
        }

        @include media ("<desktop") {
            margin-bottom: 25px;
        }

        @include media ("<tablet") {
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }
    }

    .block-text {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        @include media ("<widescreen") {
            margin-bottom: 30px;
        }

        @include media ("<desktop") {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }
    }

    .block-description {
        width: 55%;
        margin: 0 15px 0 0;

        @include media ("<desktop") {
            width: 100%;
            margin: 0;
        }

        @include media ("<tablet") {
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }
    }

    .block-img {
        width: 39%;
        min-height: 550px;
        position: relative;

        @include media ("<desktop") {
            width: 100%;
            margin-bottom: 50px;
            max-height: 450px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .hidden {

        @include media ("<phone") {
            display: none;
        }
    }

    .person-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .icon {
        display: flex;
        align-items: center;

        @include media ("<phone") {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 20px;
        }
    }

    .icon-img {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        position: relative;
        margin: 0 15px 0 0;


        @include media ("<phone") {
            margin: 0 0 20px 0;
            width: 75px;
            height: 75px;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .person-icon {

        @include media ("<tablet") {
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
            display: flex;
            flex-direction: column;
        }
    }

    .name {
        font-weight: 700;
        font-size: 18px;
        line-height: 21 / 18 * 100%;
        margin-bottom: 3px;
    }

    .sub-name {
        font-weight: 300;
        font-size: 13px;
        line-height: 15 / 13 * 100%;
        color: $white;
    }

    .date {
        font-weight: 400;
        font-size: 16px;
        line-height: 21 / 16 * 100%;
        color: $white;
    }
}

/* --------------------------#Company--------------------- */

.company {
    position: relative;
    padding: 140px 0 200px 0;

    @include media ("<widescreen") {
        padding: 65px 0 180px 0;
    }

    @include media ("<tablet") {
        padding: 50px 0 100px 0;
    }

    .cont {
        @include media ("<phone") {
            padding: 0;
        }
    }

    .title-text {
        text-align: center;
        margin: 0 0 115px 0;

        @include media ("<widescreen") {
            margin-bottom: 100px;
        }

        @include media ("<tablet") {
            padding: 0 15px;
            margin: 0 0 25px 0;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }
    }

    .title {
        margin-bottom: 25px;

        @include media ("<widescreen") {
            margin-bottom: 40px;
        }

        @include media ("<tablet") {
            margin-bottom: 15px;
        }
    }

    .company-description {
        width: 60%;

        @include media ("<widescreen") {
            width: 100%;
        }
    }

    .content-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media ("<desktop") {
            flex-direction: column;
            justify-content: center;
        }
    }

    .block-img {
        width: 49%;
        margin-right: 20px;

        @include media ("<desktop") {
            margin: 0 0 25px 0;
            width: 75%;
        }

        @include media ("<tablet") {
            margin: 0 0 25px 0;
            width: 100%;
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .block-text {
        width: 45%;

        @include media ("<desktop") {
            width: 100%;
        }

    }

    .text {
        font-size: 20px;
        line-height: 23 / 20 * 100%;
        margin-bottom: 95px;

        @include media ("<widescreen") {
            margin-bottom: 65px;
            font-size: 15px;
        }

        @include media ("<desktop") {
            width: 75%;
            margin: 0 auto;
            margin-bottom: 65px;
        }

        @include media ("<tablet") {
            margin-bottom: 35px;
            font-size: 14px;
            width: 100%;
            text-align: center;
            padding: 0 15px;
        }

        @include media ("<phone") {
            padding: 0 10px;
        }
    }

    .cards-company {
        max-height: 940px;
        overflow: auto;

        @include media ("<widescreen") {
            max-height: 830px;
        }

        @include media ("<desktop") {
            max-height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .card-blog {
        display: block;
        max-width: 645px;
        width: 100%;

        &:hover {
            transition: all 0.4s ease;

            .card-img {
                position: relative;

                &::after {
                    opacity: 0.4;
                    transition: all 0.4s ease;
                }
            }

            .card-title {
                background: $orange;
            }
        }

        @include media ("<desktop") {
            width: 75%;
        }

        @include media ("<tablet") {
            width: 100%;
        }

        &:not(:last-child) {
            margin-bottom: 50px;

            @include media ("<widescreen") {
                margin-bottom: 30px;
            }

            @include media ("<tablet") {
                margin-bottom: 25px;
            }
        }
    }

    .card-img {
        position: relative;
        @include adaptiv-vaule("min-height", 380, 190, 1);


        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background: $black;
            transition: all 0.4s ease;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }

    .card-title {
        font-weight: 700;
        display: block;
        color: $white;
        font-size: 25px;
        line-height: 29 / 25 *100%;
        padding: 15px 30px;
        background: #5F5F5F;
        margin: 0;
        transition: all 0.4s ease;

        @include media ("<widescreen") {
            font-size: 15px;
            padding: 8px 20px;
        }

        @include media ("<tablet") {
            font-size: 13px;
            padding: 6px 15px;
        }
    }

}

.contact-email {
    position: relative;
    transform: skewY(-5deg);
    padding: 150px 0 250px 0;
    background: #1D1D1D;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    @include media ("<widescreen") {
        padding: 100px 0 200px 0;
    }

    @include media ("<tablet") {
        padding: 80px 0 150px 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 20px solid transparent;
        border-left: 100vw solid $orange;
        border-top: 0px solid $orange;
    }

    .title {
        font-weight: 700;
        font-size: 48px;
        margin-bottom: 115px;

        @include media ("<widescreen") {
            margin-bottom: 90px;
            font-size: 36px;
        }

        @include media ("<tablet") {
            margin-bottom: 50px;
        }
    }

    .content-email {
        transform: skewY(5deg);
        text-align: center;
    }

    form {
        max-width: 100%;
        background: transparent;
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 0;
        flex-wrap: wrap;

        @include media ("<widescreen") {
            justify-content: center;
        }

        @include media ("<phone") {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .input {
        border: none;
        border-bottom: 1px solid #B3B3B3;
        width: 30%;

        @include media ("<widescreen") {
            width: 45%;
            margin-bottom: 90px;

            &:first-child {
                margin-right: 45px;

                @include media ("<phone") {
                    margin-right: 0;
                }
            }
        }

        @include media ("<phone") {
            width: 100%;
            text-align: center;
            margin-bottom: 60px;

            &:last-child {
                margin-bottom: 40px;
            }
        }

        &::placeholder {
            color: $gray;
            font-size: 18px;
        }
    }

    button {
        border: none;
    }
}