header {
    position: relative;
}

.header-burger {
    display: none;
    position: relative;
    width: 60px;
    height: 60px;
    background: $orange;
    z-index: 4;

    span,
    &::before,
    &::after {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        width: 26px;
        height: 3px;
        border-radius: 5px;
        background: $white;
        transition: 0.4s;
    }

    span {
        top: 29px;
    }

    &::before {
        content: '';
        top: 21px;
    }

    &::after {
        content: '';
        bottom: 20px;
    }

    @include media("<minscreen") {
        display: block;
    }

    @include media ("<tablet") {
        width: 60px;
        height: 60px;
    }
}

.burger_active {

    &::before {
        transform: rotate(45deg);
        top: 29px;
        left: 18px;
    }

    &::after {
        transform: rotate(-45deg);
        bottom: 28px;
        left: 18px;
    }

    span {
        left: 100px;
        opacity: 0;
    }
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    display: flex;
    align-items: center;

    @include media ("<minscreen") {
        justify-content: space-between;
        width: 100%;
    }
}

.logo-img {
    position: relative;
    background: $violet;
    margin: 0 30px 0 0;
    padding: 13px 25px 17px 115px;
    transition: all 0.4s ease;
    z-index: 4;

    &:hover {
        background: $orange;
    }

    @include media ("<highscreen") {
        padding: 17px 20px 13px 90px;
    }

    @include media ("<widescreen") {
        padding: 10px 18px;

    }

    @include media ("<minscreen") {
        margin: 0;
    }

    img {

        @include media ("<widescreen") {
            max-width: 40px;
            max-height: 40px;
        }
    }
}

.name-logo {
    width: 145px;
    height: 35px;
    margin-right: 15px;
    z-index: 4;

    @include media ("<minscreen") {
        margin: 0;
    }

    @include media ("<widescreen") {
        width: 115px;
        height: 30px;
    }

    @include media ("<tablet") {
        width: 80px;
        height: 20px;
    }
}

.nav {
    display: flex;

    @include media ("<minscreen") {
        position: absolute;
        z-index: 3;
        top: -100vh;
        left: 0;
        transition: all 0.6s ease;
        width: 100%;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: $orange;
        padding: 70px 25px 0 25px;
    }

    ul {
        display: flex;
        align-items: center;

        @include media ("<minscreen") {
            flex-direction: column;
            overflow: scroll;
            padding: 0 25px;
            width: 100%;
        }
    }

    .menu-li {
        list-style: none;
        @include adaptiv-vaule("margin-left", 50, 1, 1);
        position: relative;

        &:first-child {
            margin-left: 0;
        }


        @include media ("<minscreen") {
            margin: 0 0 20px 0;
        }

        a {
            font-weight: 500;
            white-space: nowrap;
            font-size: 22px;
            color: $gray;

            &:hover {
                color: $white;
                transition: 0.3s;
            }

            @include media ("<widescreen") {
                font-size: 16px;
            }

            @include media ("<minscreen") {
                color: $white;
                @include adaptiv-vaule("font-size", 36, 22, 1);

                &:hover {
                    color: $gray;
                    transition: 0.3s;

                }
            }
        }
    }

    .list {
        text-align: center;
        margin-bottom: 0;
        z-index: 1;

        img {
            vertical-align: 6px;
            margin-left: 5px;
            transition: all 0.5s ease;

            @include media ("<minscreen") {
                display: none;
            }
        }


        &:hover {

            img {
                transform: rotate(180deg);
                transition: all 0.5s ease;
            }

            .sub-menu {
                top: 0;
                transition: all 0.5s ease;
                visibility: visible;
                pointer-events: all;
                opacity: 1;
            }
        }
    }

    .sub-menu {
        position: absolute;
        top: -100%;
        left: 0;
        margin-top: 20px;
        padding: 15px 0;
        transition: all 0.7s ease;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        z-index: -1;

        @include media ("<minscreen") {
            top: 0;
            position: static;
            margin: 0;
            padding: 0;
            align-items: center;
            visibility: visible;
            opacity: 1;
        }

        a,
        li {
            padding: 5px 0;

            @include media ("<minscreen") {
                padding: 0;
                margin-bottom: 20px;

                &:first-child {
                    margin-top: 20px;
                }
            }
        }
    }


    .call {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $orange;
        color: $white;
        font-weight: 500;
        transition: 0.4s;
        font-size: 22px;
        padding: 41px 116px 41px 65px;
        margin-left: 30px;

        &:hover {
            background: $violet;
            transition: 0.4s;
        }

        @include media ("<highscreen") {
            padding: 42px 45px 41px 45px;
        }

        @include media ("<widescreen") {
            font-size: 16px;
            padding: 21px 20px 20px 20px;
        }

        @include media ("<minscreen") {
            @include adaptiv-vaule("font-size", 36, 22, 1);
            background: $violet;
            margin: 0;
            padding: 15px;
            border-radius: 5px;
            font-size: 16px;
            margin: 10px 0;

            &:hover {
                background: #2e1699;
                transition: 0.4s;
            }
        }
    }
}

.menu_active {
    top: 0;
}